import React from "react"
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share"
import {
  FaFacebookF,
  FaFacebookMessenger,
  FaPinterestP,
  FaTwitter,
} from "react-icons/fa"

function ShareButtons({ title, url, twitterHandle, tags }) {
  return (
    <div className="md-share">
      <h2 className="md-share__title">Share this recipe :</h2>
      <div className="md-share__btns">
        <ul className="md-share__list">
          <li className="md-share__item">
            <FacebookShareButton url={url}>
              <FaFacebookF color={"#fff"} size={20} />
            </FacebookShareButton>
          </li>
          <li className="md-share__item">
            <FacebookMessengerShareButton url={url}>
              <FaFacebookMessenger color={"#fff"} size={20} />
            </FacebookMessengerShareButton>
          </li>
          <li className="md-share__item">
            <PinterestShareButton url={url} title={title}>
              <FaPinterestP color={"#fff"} size={20} />
            </PinterestShareButton>
          </li>
          <li className="md-share__item">
            <TwitterShareButton
              url={url}
              title={title}
              via={twitterHandle}
              hashtags={tags}
            >
              <FaTwitter color={"#fff"} size={20} />
            </TwitterShareButton>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ShareButtons
