import React from "react"
import CustomPage from "../components/customPage/CustomPage"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Link } from "gatsby"
import urlSlug from "url-slug"
import Seo from "../components/seo/Seo"
import { graphql, useStaticQuery } from "gatsby"
import ShareButtons from "../components/shareButtons/ShareButtons"
import { useLocation } from "@reach/router"
import { FaClock, FaUtensils } from "react-icons/fa"

function Blog({ pageContext }) {
  const { site } = useStaticQuery(query)
  const {
    description,
  } = site.siteMetadata
  const { href } = useLocation()

  const { nextPost, previousPost, post } = pageContext;

  return (
    <>
      <Seo
        title={post.title.toUpperCase()}
        description={description || "nothin"}
        image={post.image.fluid.src}
        pathname={post.slug}
        article
      />
      <CustomPage title={post.title} date={post.publishDate}>
        <div className="md-page__container">
          <div className="md-post">
            {post.servings && post.cooktime && post.preptime && (
              <div className="md-post__info">
                <ul className="md-post__list">
                  <li className="md-post__item">
                    <FaClock className="md-post__icon"/>
                    {`Prep Time: ${post.preptime}`} 
                  </li>
                  <li className="md-post__item">
                    <FaClock className="md-post__icon"/>
                    {`Cook Time: ${post.cooktime}`} 
                  </li>
                  <li className="md-post__item">
                    <FaUtensils className="md-post__icon"/>
                    {`Yield: ${post.servings}`} 
                  </li>
                </ul>
              </div>
            )}
            <div className="md-post__row">
              <div className="md-post__col md-post__col--left">
                <div className="md-post__img-wrapper">
                  <Img
                    className="md-post__img"
                    fluid={post.image.fluid}
                    alt={post.title}
                  />
                </div>
              </div>
              <div className="md-post__col md-post__col--right">
                {post.ingredients && (
                  <>
                    <h2 className="md-post__subtitle">ingredients</h2>
                    <div className="md-post__content">
                      {documentToReactComponents(post.ingredients.json)}
                    </div>
                  </>
                )}
                {post.instructions && (
                  <>
                    <h2 className="md-post__subtitle">preparation</h2>
                    <div className="md-post__content">
                      {documentToReactComponents(post.instructions.json)}
                    </div>
                  </>
                )}
              </div>
            </div>

            {post.tags && (
              <ul className="md-post__list md-post__list--tags">
                {post.tags.map((tag, index) => (
                  <li className="md-post__item" key={index}>
                    <Link
                      className="md-post__link"
                      to={`/tag/${tag.toLowerCase().trim()}`}
                    >
                      {tag}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
            <ShareButtons title={post.title} url={href} tags={post.tags} />
          </div>
          {(previousPost || nextPost) && (
            <div className="md-posts">
              {previousPost && (
                <div className="md-posts__post md-posts__post--previous">
                  <div className="md-posts__post-img-wrapper md-posts__post-img-wrapper--previous">
                    <Img
                      className="md-posts__post-img"
                      fluid={previousPost.image.fluid}
                      alt={previousPost.title}
                    />
                  </div>
                  <Link
                    className="md-posts__post-link md-posts__post-link--previous"
                    to={`/${post.slug}/${urlSlug(previousPost.title)}`}
                  >
                    <span className="md-posts__text">previous post</span>
                    <h2 className="md-posts__post-title">
                      {previousPost.title.slice(0, 25).concat("...")}
                    </h2>
                  </Link>
                </div>
              )}

              {nextPost && (
                <div className="md-posts__post md-posts__post--next">
                  <div className="md-posts__post-img-wrapper md-posts__post-img-wrapper--next">
                    <Img
                      className="md-posts__post-img"
                      fluid={nextPost.image.fluid}
                      alt={nextPost.title}
                    />
                  </div>
                  <Link
                    className="md-posts__post-link md-posts__post-link--next"
                    to={`/${post.slug}/${urlSlug(nextPost.title)}`}
                  >
                    <span className="md-posts__text">next post</span>
                    <h2 className="md-posts__post-title">
                      {nextPost.title.slice(0, 25).concat("...")}
                    </h2>
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      </CustomPage>
    </>
  )
}

export default Blog

const query = graphql`
  query postSEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`
